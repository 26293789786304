import Alert from '@mui/material/Alert';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  donateBannerOpenStateAtom,
  redirectBannerOpenStateAtom,
} from 'recoil/atoms';
import Link from '@mui/material/Link';
import NextLinkMui5 from '../../components/atoms/NextLink';
import { red } from '@mui/material/colors';

const BannerAlert = styled(Alert)(
  ({ theme }) => `
  &.MuiAlert-root {
    padding: ${theme.spacing(0, 2)};
    border-radius: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    & .MuiAlert-icon {
      display: none;
    }
    & .MuiAlert-message {
      font-weight: bold;
      margin: 0 auto;
      width: 100%;
      text-align: center;
    }
  }`,
);

type BannerProps = {
  background?: string;
};

export const DonateBanner: FC<BannerProps> = ({ background = red[800] }) => {
  const [isOpened, setIsOpened] = useRecoilState(donateBannerOpenStateAtom);
  const isRedirectBannerOpened = useRecoilValue(redirectBannerOpenStateAtom);

  return (
    <>
      {!isRedirectBannerOpened && isOpened && (
        <BannerAlert
          variant='filled'
          style={{ background }}
          onClose={() => {
            setIsOpened(false);
          }}
        >
          <b>
            Drive Into Black Friday Savings 🏷{' '}
            <Link
              component={NextLinkMui5}
              sx={{ color: 'white', textDecorationColor: 'white' }}
              href='/deals?is_featured=true'
            >
              Check out
            </Link>{' '}
            Leasing Deals You&apos;ll be Thankful for 🔥 🔥 🔥
          </b>
        </BannerAlert>
      )}
    </>
  );
};
